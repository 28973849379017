@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  @apply bg-gray-50;
}

/* Custom animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

/* Custom button styles */
.btn-primary {
  @apply bg-blue-700 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-500 transition-all duration-300 ease-in-out shadow-sm;
}

.btn-secondary {
  @apply bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-md hover:bg-gray-300 transition-all duration-300 ease-in-out shadow-sm;
}

.btn-danger {
  @apply bg-red-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-red-600 transition-all duration-300 ease-in-out shadow-sm;
}

/* Form styles */
.form-input {
  @apply block w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-400 focus:border-blue-400 transition-all duration-300 ease-in-out;
}

.page-transition {
  @apply transition-all duration-300 ease-in-out;
}

/* Card styles */
.card {
  @apply bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg;
}

/* Section styles */
.section {
  @apply py-12 px-4;
}

.section-title {
  @apply text-3xl font-bold text-gray-900 mb-8;
}

@keyframes dropdown-fade-in {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
